<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header dark class="secondary white--text"
        >Job Summary</v-expansion-panel-header
      >
      <v-expansion-panel-content>
        <div
          class="d-flex flex-column align-center my-2 grey--text text--darken-2"
        >
          <span class="title">{{
            appointmentToUse.service_category.name
          }}</span>
          <span class="caption">Order placed {{ date }}</span>
          <span class="caption">Contact {{ job.customer.email }}</span>
        </div>
        <v-divider class="my-2"></v-divider>
        <div>
          <div class="d-flex justify-space-between">
            <v-subheader>Notes</v-subheader>
          </div>
          <CommentsTable :job-id="job.id" />
        </div>
        <v-divider class="my-2"></v-divider>
        <div>
          <div class="d-flex justify-space-between">
            <v-subheader>Files</v-subheader>
            <v-tooltip left>
              <template #activator="{ on }">
                <label>
                  <v-btn
                    text
                    icon
                    color="secondary"
                    v-on="on"
                    @click="triggerUpload"
                  >
                    <v-icon>mdi-image-plus</v-icon>
                  </v-btn>
                  <input
                    ref="fileUpload"
                    class="file-input"
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    name="photos"
                    multiple
                    @change="handleUpload"
                  />
                </label>
              </template>
              <span>Add Photos</span>
            </v-tooltip>
          </div>
          <PhotoGallery
            :loading="uploading"
            :job-id="job.id"
            :photos="visiblePhotos"
          />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import {mapActions} from "vuex";

import PhotoGallery from "@/components/PhotoGallery.vue";
import CommentsTable from "../views/CommentsTable.vue";

export default {
  name: "ServiceSummary",
  components: {
    PhotoGallery,
    CommentsTable
  },
  props: {
    appointment: {
      type: Object,
      default: () => null
    },
    job: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      photos: [],
      files: [],
      uploading: false
    };
  },
  computed: {
    appointmentToUse() {
      if (this.appointment) return this.appointment;
      if (!this.job) {
        return null;
      }
      const { appointments } = this.job;
      return appointments.find((appt) => appt.id === this.$route.params.id);
    },
    visiblePhotos() {
      return this.job.photos.filter((photo) => photo.customer_visible);
    },
    date() {
      if (!this.appointmentToUse) return "--";
      if (!this.appointmentToUse.scheduled_time) return "--";
      return new Date(this.appointmentToUse.scheduled_time).toLocaleDateString(
        "en-us"
      );
    }
  },
  methods: {
    ...mapActions(["fetchJob", "reserveS3Photos", "uploadS3Photo"]),
    async handleUpload(e) {
      this.uploading = true;

      try {
        await this.performUpload(e.target.files);

        await this.fetchJob(this.appointmentToUse.job_id);

        this.uploading = false;
      } catch (e) {
        alert(e.message);
        this.uploading = false;
      }
    },
    async performUpload(files) {
      const reservations = await this.createReservations(files);

      const allRequests = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // read the file so it is ready for upload
        await file.bytes();

        const reservation = reservations[i];

        allRequests.push(
          this.uploadS3Photo({
            file,
            reservation
          })
        );
      }

      return Promise.all(allRequests);
    },
    async createReservations(files) {
      const extensions = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        extensions.push(file.name.split(".").pop());
      }

      const payload = {
        job: this.job,
        extensions
      };

      const response = await this.reserveS3Photos(payload);
      return response.data;
    },
    triggerUpload() {
      this.$refs.fileUpload.click();
    }
  }
};
</script>

<style lang="scss" scoped>
.file-input {
  display: none;
}
</style>
